import ReactGA from 'react-ga';
import likesLogo from './likes.svg';
import dislikesLogo from './dislikes.svg';
import screenshot from './screenshot.png';
import screenshotInstallLikes from './screenshot_install_likes.png';
import screenshotInstallDislikes from './screenshot_install_dislikes.png';
import screenshotPinExtensions from './screenshot_pin_extensions.png';
import videoPinExtensions from './pin_extensions.mp4';
import screenshotClickIcon from './screenshot_click_icon.png';

const logClick = (info) => {
  ReactGA.event({
    category: 'click',
    action: 'homepage: ' + info
  });
}

const App = () => (
  <div itemScope itemType="https://schema.org/WebApplication" className="flex flex-col items-center space-y-12 md:space-y-24 my-12 md:my-24">
    <div className="relative max-w-3xl md:w-full px-6 space-y-2">
      <p itemProp="name identifier" className="max-w-lg text-xl font-black">WebRep</p>
      <h1 className="max-w-lg text-5xl font-bold">Like any web page</h1>
      <p itemProp="description" className="max-w-lg pt-2">The WebRep browser extension enables you to like and dislike any web page with the click of a button, and to see the number of likes/dislikes.</p>
      <div className="hidden md:block -z-10 w-full h-full absolute top-0 right-0">
        <img src={likesLogo} alt="likes logo" className="absolute -top-8 right-16" />
        <img src={dislikesLogo} alt="dislikes logo" className="-z-20 absolute top-8 right-3" />
      </div>
    </div>

    <div className="flex flex-col md:flex-row items-center md:items-start max-w-3xl px-6 space-y-12 md:space-y-0 md:space-x-12">
      <img itemProp="screenshot" src={screenshot} alt="screnshot" className="rounded-full border-2" />
      <ol itemScope itemType="https://schema.org/HowTo" className="steps flex flex-col max-w-xl">
        <div>
          <meta itemProp="name about" content="How to install the WebRep browser extension" />
          <meta itemProp="performTime totalTime" content="PT1M" />
        </div>

        <li itemProp="step" itemScope itemType="https://schema.org/HowToStep" className="!mt-0">
          <a itemProp="url" href="https://chrome.google.com/webstore/detail/oikplfhhabonfjkebdehflhlddahbalb" onClick={() => logClick('install likes extension')} target="_blank" rel="noreferrer" className="inline-block bg-blue text-white rounded p-3 hover:bg-blue-lighter">
            <span>Install the <span className="material-icons md-18 md-light mx-1">thumb_up</span> extension</span>
          </a>
          <meta itemProp="name" content="Install the likes extension" />
          <meta itemProp="text" content="Install the WebRep likes extension from the Web Store." />
          <meta itemProp="image" content={screenshotInstallLikes} />
        </li>
        <li itemProp="step" itemScope itemType="https://schema.org/HowToStep">
          <a itemProp="url" href="https://chrome.google.com/webstore/detail/lfbapmlffhillfiaollijeaipaponnag" onClick={() => logClick('install dislikes extension')} target="_blank" rel="noreferrer" className="inline-block bg-blue text-white rounded p-3 hover:bg-blue-lighter">
            <span>Install the <span className="material-icons md-18 md-light mx-1">thumb_down</span> extension</span>
          </a>
          <meta itemProp="name" content="Install the dislikes extension" />
          <meta itemProp="text" content="Install the WebRep dislikes extension from the Web Store." />
          <meta itemProp="image" content={screenshotInstallDislikes} />
        </li>
        <li itemProp="step" itemScope itemType="https://schema.org/HowToStep">
          <link itemProp="url" href="https://webrep.app/" />
          <p itemProp="name" className="font-bold">Pin the two extensions</p>
          <p itemProp="text">Click on the <span className="material-icons md-18">extension</span> icon at the top right corner of the window, then click on the <span className="material-icons-outlined md-18">push_pin</span> icon next to the two WebRep extensions to pin them to the extensions bar.</p>
          <meta itemProp="image" content={screenshotPinExtensions} />
          <div itemProp="video" itemScope itemType="https://schema.org/VideoObject">
            <meta itemProp="name description" content="How to pin the WebRep extensions" />
            <meta itemProp="url contentUrl" content={videoPinExtensions} />
            <meta itemProp="thumbnailUrl" content={screenshotPinExtensions} />
            <meta itemProp="uploadDate" content="2022-04-11T15:48:58.722Z" />
          </div>
        </li>
        <li itemProp="step" itemScope itemType="https://schema.org/HowToStep">
          <link itemProp="url" href="https://webrep.app/" />
          <p itemProp="name" className="font-bold">You're all set!</p>
          <p itemProp="text">Click on the icon to use the extension.</p>
          <meta itemProp="image" content={screenshotClickIcon} />
        </li>
      </ol>
    </div>

    <a href="https://discord.gg/aX7w7zHNfd" onClick={() => logClick('join discord')} target="_blank" rel="noreferrer" className="goto max-w-3xl text-right w-full text-blue hover:underline px-6">Join me on Discord</a>

    <div className="!m-0">
      <meta itemProp="author" content="Maxence Maire" />
      <meta itemProp="image" content={likesLogo} />
      <meta itemProp="applicationCategory" content="BrowserApplication" />
      <meta itemProp="applicationSubCategory" content="Social" />
      <link itemProp="url" href="https://webrep.app/" />
      <link itemProp="installUrl" href="https://chrome.google.com/webstore/detail/oikplfhhabonfjkebdehflhlddahbalb" />
      <link itemProp="installUrl" href="https://chrome.google.com/webstore/detail/lfbapmlffhillfiaollijeaipaponnag" />
      <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
        <span itemprop="priceCurrency" content="USD" />
        <meta itemprop="price" content="0" />
      </div>
      <meta itemProp="operatingSystem" content="All" />
    </div>
  </div>
);

export default App;
